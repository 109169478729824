import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Cookies from './services/cookies'
import { FocusTrap } from 'focus-trap-vue'
let Run = async () => {
    let api = 'http://localhost:8787'
    if(window.location.hostname != 'localhost') api = 'https://notesandthings-api.originalbuilders.workers.dev'
    store.commit('SetState',{key:'api',value:api})
    store.commit('SetState',{key:'cookies',value:Cookies})
    store.commit('SetState',{key:'token',value:Cookies.get('nat:token')})
    if(store.state.token) {
        let response = await fetch(`${api}/check/`,{
            method: "POST",
            cache: 'no-cache',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:store.state.token}),
        })
        response = await response.json();
        if(response && response.status && response.status == '1') {
            store.commit('SetState',{key:'user',value:response.user})
        }else {
            store.dispatch('Logout')
        }
    }
    createApp(App).use(store).use(router).component('FocusTrap', FocusTrap).mount('#app')
}


Run()