import { createStore } from 'vuex'
import Note from './modules/note'
export default createStore({
  state: {
    tinyKey: '4aly5xyqv2z3b2j1opfict9v22l0j1jzlvj3dikh1l646z62',
    cookies: false,
    slidedown_open: false,
    slidedown_content: false,
    slideup_open: false,
    slideup_content: false,
    token: false,
    user: false,
    api: false,
    notes: [],
    loading: {
      notes: false,
    },
  },
  mutations: {
    SetState(state,payload) {
      state[payload.key]=payload.value
    },
    AddNote(state,note) {
      state.notes.unshift(note)
    }
  },
  actions: {
    async GetNotes({state}) {
      state.loading.notes = true
      let response = await fetch(`${state.api}/notes/?token=${state.token}`,{
        cache: 'no-cache',
      })
      state.notes = await response.json();
      state.loading.notes = false
    },
    SlideUp({state,dispatch},payload) {
      dispatch('ClearSlideDown')
      state.slideup_open = true 
      state.slideup_content = payload.component
    },
    SlideOut({state,dispatch},payload) {
      dispatch('ClearSlideUp')
      state.slidedown_open = true 
      state.slidedown_content = payload.component
    },
    ClearSlideUp({state}) {
      state.slideup_open = false
      state.slideup_content = false
    },
    ClearSlideDown({state}) {
      state.slidedown_open = false
      state.slidedown_content = false
    },
    Login({state},payload) {
      state.user = payload.user
      state.token = payload.token 
      state.cookies.set('nat:token',payload.token,7)
    },
    Logout({state},payload) {
      state.user = false
      state.token = false
      state.cookies.delete('nat:token')
    },
  },
  modules: {
    'note':Note,
  }
})
