export default {
    namespaced: true,
    state: {
        note: false,
    },
    mutations: {
      SetState(state,payload) {
        state[payload.key]=payload.value
      },
      UpdateNoteContent(state,content) {
        state.note.content = content
      },
    },
    actions: {
    }
}